import Vue from 'vue'
import BaseBtn from '@/components/base/Btn'
import BaseCard from '@/components/base/Card'
import BaseSubheading from '@/components/base/Subheading'
import BaseMainTitle from '@/components/base/MainTitle'
import BaseRouterLink from '@/components/base/RouterLink'
import ProgressCircular from '@/components/base/ProgressCircular'
import { LazyYoutube } from "vue-lazytube";

import mixin from '@/constants/mixin'
Vue.mixin(mixin)

 const moment = require('moment')
require('moment/locale/ar')
Vue.use(require('vue-moment'), {
   moment
})

import Carousel3d from 'vue-carousel-3d';

Vue.use(Carousel3d)
Vue.component(BaseBtn.name, BaseBtn)
Vue.component(BaseCard.name, BaseCard)
Vue.component(BaseSubheading.name, BaseSubheading)
Vue.component(BaseMainTitle.name, BaseMainTitle)
Vue.component(BaseRouterLink.name, BaseRouterLink)
Vue.component(ProgressCircular.name, ProgressCircular)
Vue.component(LazyYoutube.name, LazyYoutube)



import { Stack, StackItem } from 'vue-stack-grid';

Vue.component('Stack', Stack)
Vue.component('StackItem', StackItem)
