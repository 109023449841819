const BASE_URL = "https://qr-dashboard.turathalanbiaa.com/api";
const APP_KEY ="base64:ySIJF4QhgwW7SxzTdEfJBfap+JBK5jk9zYJzFaJqYEQ=";
const BASE_IMAGE_URL = "https://qr-dashboard.turathalanbiaa.com/storage/assets/";
const GOOGLEMAP_URL = "https://www.google.com/maps/place/%D9%85%D8%AC%D9%85%D8%B9+%D8%A7%D9%84%D9%85%D8%B1%D8%AA%D8%B6%D9%89+%D8%A7%D9%84%D8%AB%D9%82%D8%A7%D9%81%D9%8A%2F+%D8%A7%D9%84%D8%B9%D8%AA%D8%A8%D8%A9+%D8%A7%D9%84%D8%B9%D8%A8%D8%A7%D8%B3%D9%8A%D8%A9+%D8%A7%D9%84%D9%85%D9%82%D8%AF%D8%B3%D8%A9%E2%80%AD/@32.0035428,44.3350522,17z/data=!3m1!4b1!4m5!3m4!1s0x155ed73beecdd53d:0xc8ddab3ecb533b04!8m2!3d32.0036375!4d44.3305168?hl=ar";


// const BASE_FILE_URL = "http://localhost:8000/storage/files/";


export{
    BASE_URL ,
    APP_KEY,
    BASE_IMAGE_URL,
    GOOGLEMAP_URL
    // BASE_PROFILE_URL,
    // BASE_FILE_URL,   
}






