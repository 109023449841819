<template>
  <v-row class="fill-height" align="center" justify="center" v-if="loading">
    <div class="mt-6">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-row>
</template>
<script>
// Utilities
  import {
    mapGetters,
  } from 'vuex'
export default {
  name: 'ProgressCircular',

     computed: {
     ...mapGetters(['loading']),
    },
  
};
</script>